<template>
  <div class="bannerDetail "
       style="min-height: 500px;">
    <nav-view :titleName="'详情'"></nav-view>
    <div class="centerWidth marginAuto"
         style="margin-top: 30px;min-height: 300px;"
         v-html="bannerDe"></div>
  </div>
</template>

<script>
import navView from '../components/navView.vue'
import { advert_list } from '@/utils/Api/homeList'
export default {
  name: 'bannerDetail',
  data () {
    return {
      bannerDe: ''
    }
  },
  created () {
    advert_list({
      type: this.$route.query.type,
      advert_id: this.$route.query.advert_id
    }).then(res => {
      // console.log(res)
      this.bannerDe = res.content
    })
  },
  components: { navView }
}
</script>

<style>
</style>
